import React from 'react';
// import successImage from '/account-setup/success.png'; 
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
const AccountSetupFinal = () => {
  const navigate = useNavigate();

  const onClickRedirect = () => {
    navigate("/home");
  }

  return (
    <div className="relative w-full h-screen custom-bg-color">
      <img
        src='/account-setup/success.png'
        alt="Success"
        className="absolute  inset-0 w-full h-full object-cover"
      />
      <div className="absolute inset-0 custom-bg-color opacity-25"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
        <div className="bg-white rounded-full h-[200px] w-[200px] mb-5" ></div>
        <h1 className="text-white text-4xl font-bold text-center">
          Your Business Profile is Setup!
        </h1>
        <Button
          variant="contained"
          color='secondary'
          className='rounded-2xl mt-5 w-[30%]'
        onClick={onClickRedirect}
        // disabled={!agree}
        >
          Continue to Homepage
        </Button>
      </div>
    </div>
  );
};

export default AccountSetupFinal;
