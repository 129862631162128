import {
    useEffect,
    useState
} from 'react';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { Button } from '@mui/material';

import { StyledStepper } from '../../components/StyledStepper';
import AccountBusinessName from './AccountBusinessName';
import AccountAboutBusiness from './AccountAboutBusiness';
import AccountService from './AccountService';
import AccountTeamSize from './AccountTeamSize';
import AccountLocation from './AccountLocation';
import AccountSoftwareUsing from './AccountSoftwareUsing';
import AccountReferral from './AccountReferral';
import AccountSetupFinal from './AccountSetupFinal';
import AccountFindBusiness from './AccountFindBusiness';

export default function AccountSetup() {
    const [activeStep, setActiveStep] = useState(1);

    const handleClickNext = () => {
        //todo: will handle api call in one place here to save business info
        if (activeStep < 9) {
            setActiveStep(activeStep + 1);
        } else {
            // reached final step
        }
    };

    const handleClickPrevious = (e) => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        } else {
            // reached final step
        }
    };

    return (
        <div className='text-pr'>
            {activeStep !== 9 ? (
                <>
                    <div className='header-wrapper'>
                        <div className='mx-3 mt-5 flex flex-row'>
                            {activeStep !== 1 && (
                                <KeyboardBackspaceIcon 
                                    className='cursor-pointer'
                                    onClick={handleClickPrevious}
                                />
                            )}
                            <div className='text-center w-full text-3xl'>Account Setup</div>
                        </div>
                        <StyledStepper
                            variant="progress"
                            steps={10}
                            position="static"
                            activeStep={activeStep}
                        />
                    </div>
                    <div className='account-setup--wrapper flex flex-col mx-auto max-w-2xl lg:max-w-4xl p-6 mt-5'>
                        <div>
                            {activeStep === 1 && <AccountBusinessName onClickNext={handleClickNext} />}
                            {activeStep === 2 && <AccountAboutBusiness onClickNext={handleClickNext} />}
                            {activeStep === 3 && <AccountFindBusiness onClickNext={handleClickNext} />}
                            {activeStep === 4 && <AccountService onClickNext={handleClickNext} />}
                            {activeStep === 5 && <AccountTeamSize onClickNext={handleClickNext} />}
                            {activeStep === 6 && <AccountLocation onClickNext={handleClickNext} />}
                            {activeStep === 7 && <AccountSoftwareUsing onClickNext={handleClickNext} />}
                            {activeStep === 8 && <AccountReferral onClickNext={handleClickNext} />}
                        </div>
                    </div>
                </>
            ) : (
                <div className=''>
                    <AccountSetupFinal />
                </div>
            )}  
        </div>
    )
}
