import { useEffect, useRef } from "react";

export const DialogueBoxCard = ({children}) => {

    // Create refs for the inner content div and the outer fixed div
    const innerContentRef = useRef(null);
    const dialogueBoxCardRef = useRef(null);

    // Update the height of the dialogue-box-card div to fit the viewport height
    useEffect(() => {
        const handleResize = () => {
        dialogueBoxCardRef.current.style.height = `${window.innerHeight}px`;
        };

        // Set the initial height
        handleResize();

        // Listen for window resize events
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Event handler for scrolling the inner content
    const handleScroll = () => {
        // Get the current scroll position of the inner content
        const scrollTop = innerContentRef.current.scrollTop;

        // Set the scroll position of the outer dialogue-box-card div
        dialogueBoxCardRef.current.scrollTop = scrollTop;
    };

    return (
        <div className="dialogue-box-card overflow-y-scroll" ref={dialogueBoxCardRef}>
            <div className="inner-content" ref={innerContentRef} onScroll={handleScroll}>
                {children}
            </div>
        </div>
    )
}