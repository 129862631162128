import FooterLayout from "./FooterLayout";
import { Sidebar } from "./Sidebar";

export default function BodyLayout(props) {
    return (
        <div className="body-wrapper w-full flex">
            <div className="side-bar--wrapper">
                <Sidebar />
            </div>
            <div className="main-content w-full h-full overflow-y-auto">
                {props.children}
                {/* <FooterLayout /> */}
            </div>
        </div>
    )
}
