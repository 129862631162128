import { useState } from 'react';

import { 
    Autocomplete, 
    Box, 
    Button, 
    FormControl, 
    FormControlLabel, 
    InputLabel, 
    MenuItem, 
    Radio, 
    RadioGroup, 
    Select, 
    TextField 
} from '@mui/material';

import { StyledCheckbox } from '../../components/StyledCheckbox';
import { countries } from '../../utils/countries';

const companyType= [
    {label: "Ιδιωτική Κεφαλαιουχική Εταιρεία (ΙΚΕ)", value: "ΙΚΕ"},
    {label: "Εταιρεία Περιορισμένης Ευθύνης (ΕΠΕ)", value: "ΕΠΕ"},
    {label: "Ανώνυμη Εταιρεία (ΑΕ)", value: "ΑΕ"},
    {label: "Ομόρρυθμη Εταιρία (ΟΕ)", value: "ΟΕ"},
    {label: "Εταιρεία Ευρωπαϊκού Συμφέροντος (ΕΕ)", value: "ΕΕ"},
    {label: "Ανώνυμη Εταιρεία Ειδικού Σκοπού (ΑΕΕΣ)", value: "ΑΕΕΣ"},
    {label: "Ανώνυμη Εταιρεία με Ευθύνη (ΑΕΜΕ)", value: "ΑΕΜΕ"},
    {label: "Κοινοπραξία (ΚΟΕ)", value: "ΚΟΕ"},
];

export default function AccountAboutBusiness(props) {
    const { onClickNext } = props;

    const [businessStatus, setBusinessStatus] = useState();

    const handleChangeBusinessType = (e) => {
        setBusinessStatus(e.target.value);
    };

    const handleClickNext = (e) => {
        e.preventDefault();
        onClickNext();
    };

    return (
        <form className="w-full flex flex-col" onSubmit={handleClickNext}>
            <div className="w-full text-center space-y-3">
                <div className="text-2xl">
                    Location & Legal Status
                </div>
                <div className="text-xl">
                    Please specify your business location and legal status.
                </div>
            </div>
            <div className="mx-3 mt-8 space-y-5">
                <div className="text-xl">
                    * What is the location of your business?
                </div>
                <Autocomplete
                    id="country-select-demo"
                    // value={curCountry}
                    // onChange={(event, newValue) => {
                    //     handleCountryChange(newValue);
                    // }}
                    // size='small'
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                            />
                            {option.label} ({option.code})
                        </Box>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            // label="Country"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                            required
                            // error={!curCountry}
                        />
                    )}
                />
            </div>
            <div className='mt-12 mx-3'>
                <div className='text-xl'>What is the legal status of your business?</div>
                <RadioGroup
                    row
                    defaultValue="noBusiness"
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="businessType"
                    className='mt-2'
                    onClick={handleChangeBusinessType}
                >
                    <FormControlLabel 
                        value='company'
                        control={<Radio color="primary" required={false} />} 
                        label="I have a company"
                        className='text-gray-600'
                    />
                    <FormControlLabel 
                        value='freelancer' 
                        control={<Radio color="primary" required={false} />}
                        label="I am a freelancer"
                        className='text-gray-600'
                    />
                    <FormControlLabel 
                        value='noBusiness' 
                        control={<Radio color="primary" required={false} />}
                        label="I don't have a business"
                        className='text-gray-600'
                    />
                </RadioGroup>
                {businessStatus === 'company' && (
                    <FormControl sx={{ minWidth: 120 }} fullWidth className='mt-5'>
                        <InputLabel id="demo-simple-select-label" color='primary'>Select the company type</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id="company-type"
                            // value={companyType}
                            label="Select the company type"
                            // onChange={}
                            color='primary'
                        >
                            {companyType.map((item, index) => {
                                return (
                                    <MenuItem value={item.value} key={index}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )} 
            </div>
            <div className='mt-8'>
                <Button
                    variant="contained"
                    className="rounded-2xl"
                    type="submit"
                >
                    Next Step
                </Button>
            </div>
        </form>
    )
}
