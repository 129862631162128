import React, { useState } from "react";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ScheduleIcon from "@mui/icons-material/PlaylistAddOutlined"; // Import the icon

const UpcomingAppointmentCard = ({ title }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const appointments = []; // Your appointments array

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="relative border border-gray-300 bg-white shadow-lg rounded-lg p-6 max-w-[55rem] mt-5">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-manrope">{title}</h2>
        <IconButton onClick={handleClick} size="large">
          <MoreVertOutlinedIcon />
        </IconButton>
        <Menu
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleClose}>Edit</MenuItem>
          <MenuItem onClick={handleClose}>Delete</MenuItem>
          <MenuItem onClick={handleClose}>Reschedule</MenuItem>
        </Menu>
      </div>
      <p className="font-manrope text-[#565E6A]">Next 7 Days</p>
      {appointments.length === 0 ? (
        <div className="flex flex-col items-center justify-center space-y-2 h-48 mt-20">
          <ScheduleIcon sx={{ fontSize: 48, mt: 20 }} />
          <p className="font-manrope text-2xl">Your Schedule is empty</p>
          <p className="font-manrope text-xl">
            Make more appointments for schendule data to appear
          </p>
        </div>
      ) : (
        <div>{/* Display appointments */}</div>
      )}
    </div>
  );
};

export default UpcomingAppointmentCard;
