import { useState, } from "react";

import { 
  Button, 
  TextField 
} from "@mui/material";

import { Link, useNavigate  } from "react-router-dom";

const Signin = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate()
  const checkEmail = (e) => {
    e.preventDefault();
    if (email) {
        navigate(`/login?email=${email}`)
    }
  };
  return (
    <div className="w-full bg-[#FAF7FF] grid overflow-hidden lg:h-screen grid-cols-1 lg:grid-cols-2">
      <div className="relative w-full h-screen">
        <img
          src="/signup/create.png"
          className="w-full relative "
          alt=""
        />
        <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full z-10">
          <div className="bg-white p-5 rounded-[18px] flex items-center justify-center  w-[77%] h-[70%]">
            <div>
              <div className="flex items-center justify-center  gap-2">
                <img src="/logo.png" className="w-[130px] h-[35px]" alt="" />
                <h1 className="text-pr text-[38px] text-center  ">
                  for Partners
                </h1>
              </div>
              <p className=" text-[16px] text-sr text-center">
                Sign up or Log-in to administer your Business account.
              </p>
              <div className="max-w-[396px] mt-12 mx-auto">
                <form onSubmit={checkEmail}>
                  <TextField 
                    fullWidth 
                    id="email"
                    name="email"
                    placeholder='Enter your email'  
                    className='my-1'  
                    required
                    size="small"            
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    className='rounded-2xl mt-3 w-full'
                    // onClick={handleClickNext}
                  >
                    Continue
                  </Button>
                </form>
                <div className="flex items-center text-[14px] text-sr justify-center my-3 gap-4">
                  <div className="w-full h-[1px] bg-[#EAECF0]"></div>
                  OR
                  <div className="w-full h-[1px] bg-[#EAECF0]"></div>
                </div>
                <Link to="/">
                  <Button
                    type="submit"
                    variant="outlined"
                    className='rounded-2xl mt-3 w-full'
                    // onClick={handleClickNext}
                  >
                    Sign up
                  </Button>
                </Link>
                <div className="mt-7 flex items-center justify-center gap-4">
                  <Link to="/support">
                    <div className="flex items-center gap-1 text-purp text-[16px]">
                      <img src="/signin/support.png" alt="" /> Support
                    </div>
                  </Link>
                  <Link to="/privacy-policy">
                    <div className="flex items-center text-purp text-[16px] gap-1">
                      <img src="/signin/dot.png" alt="" /> Privacy Policy
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-pu hidden lg:block">
        <img
          src="/signin/login.png"
          className="w-full h-full object-cover"
          alt=""
        />
      </div>
    </div>
  );
};

export default Signin;
