import { NavLink } from "react-router-dom";

import { List } from '@mui/material';

import { sideBarNavigation } from '../navigation';

export const Sidebar = (props) => {
  return (
    <List className='side-bar w-[70px] h-full bg-gray-800 space-y-4'>
      {sideBarNavigation.map((item, index) => (
        <div 
          key={item.name}
          disablePadding
          className='flex justify-center text-white cursor-pointer my-3'
        >
          <NavLink
            to={item.href}
            className='text-white flex items-center hover:bg-purp rounded-md'
          >
            <item.icon 
              className='m-2 w-8 h-8'
            />
          </NavLink>
        </div>
      ))}
    </List>
  );
}
