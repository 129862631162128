import { Link } from 'react-router-dom';

import { Button } from '@mui/material';

const ResetPassword = () => {
  return (
    <div className="w-full bg-[#FAF7FF] grid overflow-hidden lg:h-screen grid-cols-1 lg:grid-cols-2">
    <div className="relative w-full  h-screen">
      <div className="absolute z-30 left-4 top-4 ">
        <Link to="/login">
          <img src="/signin/back.png" alt="" />
        </Link>
      </div>
      <img
        src="/signup/create.png"
        className="w-full relative -right-[0px]"
        alt=""
      />
      <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full z-10">
        <div className="bg-white p-5 rounded-[18px] flex items-center justify-center  w-[77%] h-[70%]">
          <div>
            <div className="max-w-[396px] mx-auto">
              <h1 className="text-pr text-center text-[24px] pl-1 leading-[26px]   ">
              Reset email sent!
              </h1>
              <p className=" text-[14px] mt-6 text-center text-sr ">
              Please check your inbox to reset your password. 
If you didn't receive your email, please check your
 junk mail folder.
              </p>

              <Link to="/sign-in">
                <Button
                  variant="contained"
                  className='rounded-2xl mt-5 w-full'
                >
                  Back to log in
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full hidden lg:block">
      <img
        src="/signin/reset.png"
        className="w-full h-full object-cover"
        alt=""
      />
    </div>
  </div>
  )
}

export default ResetPassword