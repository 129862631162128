import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import {ReactComponent as CrossIcon} from '../../assets/side-bar-icons/cross.svg';
import {ReactComponent as BackIcon} from '../../assets/side-bar-icons/back-arrow.svg';

export const DialogueBoxHeader = ({handleCloseDialogueBox}) => {

    const handleCloseDialougeBox = () => {
        handleCloseDialogueBox();
    }

    return (
        <Grid container justify="space-between" alignItems="center">
            <Grid item>
                <IconButton >
                    <BackIcon />
                </IconButton>
            </Grid>
            <Grid item className='pl-4 font-medium text-xl '>
                Select Service
            </Grid>
            <Grid item style={{ marginLeft: 'auto' }}>
                <IconButton onClick={handleCloseDialougeBox}>
                    <CrossIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}