import { Button } from '@mui/material';

import ServiceCard from '../../components/ServiceCard';
import { services } from '../../utils/services';

export default function AccountService(props) {
    const { onClickNext } = props;

    const handleClickNext = (e) => {
        e.preventDefault();
        onClickNext();
    };
    
    const handleSelect = (service) => {
        console.log('service selected: ', service);
        //todo: update store
    };

    return (
        <form className="w-full flex flex-col" onSubmit={handleClickNext}>
            <div className="w-full text-center space-y-3">
                <div className="text-2xl">
                   List your services
                </div>
                <div className="text-xl">
                    Add up to (4) services you offer to let customers know what you specialize in.
                </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 gap-4 mt-12">
                {services.map((item, index) => (
                    <ServiceCard 
                        service={item}
                        key={item.name}
                        onSelect={handleSelect}
                    />
                ))}
            </div>
            <div className='mt-8'>
                <Button
                    type="submit"
                    variant="contained"
                    className='rounded-2xl'
                >
                    Next Step
                </Button>
            </div>
        </form>
    )
}
