import React, { useState } from "react";
import ScheduleIcon from "@mui/icons-material/PlaylistAddOutlined";
const AppointmentActivityCard = ({ title }) => {
  // Sample data
  const appointments = [
    {
      date: { day: "Wed", date: "29 Feb 2024", time: "12:00pm" },
      status: "BOOKED",
      type: "Reflexology",
      name: "John Doe",
      bookedWith: "1h with Wendy",
      price: "5.50€",
    },
    {
      date: { day: "Thu", date: "30 Feb 2024", time: "2:00pm" },
      status: "PENDING",
      type: "Massage",
      name: "Jane Doe",
      bookedWith: "30min with Alex",
      price: "30.00€",
    },
    {
      date: { day: "Fri", date: "31 Feb 2024", time: "3:00pm" },
      status: "COMPLETED",
      type: "Acupuncture",
      name: "Mike Smith",
      bookedWith: "1h with Sara",
      price: "80.00€",
    },
  ];

  // Function to determine the status class
  const getStatusClass = (status) => {
    switch (status) {
      case "BOOKED":
        return "text-customblue font-manrope";
      case "STARTED":
        return "text-customGreen font-manrope";
      case "PENDING":
        return "text-customblue font-manrope";
      // Add more cases as needed
      default:
        return "text-gray-500";
    }
  };

  return (
    <div className="relative border border-gray-300 bg-white shadow-lg rounded-lg p-6 max-w-[55rem] mt-2 ml-10">
      <h2 className="text-xl font-manrope mb-4">{title}</h2>
      {appointments.length === 0 ? (
        <div className="flex flex-col items-center justify-center space-y-2 h-48">
          <ScheduleIcon sx={{ fontSize: 48 }} />
          <p className="text-lg text-gray-600">Your Schedule is empty</p>
        </div>
      ) : (
        <div className="space-y-4 border-t border-border-base">
          {appointments.map((appointment, index) => (
            <div key={index} className="flex items-center py-4">
              <div className="text-lg font-manrope mr-4">
                29
                <br /> Feb
              </div>
              <div className="flex flex-col flex-grow">
                <div className="flex items-center">
                  <span className="text-sm font-manrope text-gray-500">
                    {appointment.date.date} {appointment.date.time}
                  </span>
                  <span className={`font-manrope text-sm ${getStatusClass(appointment.status)} ml-2`}>
                    {appointment.status}
                  </span>
                </div>
                <div className="text-lg font-manrope">{appointment.type}</div>
                <div className="text-sm text-gray-500 font-manrope">
                  {appointment.name}, {appointment.bookedWith}
                </div>
              </div>
              <div className="ml-auto text-lg font-manrope">
                {appointment.price}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppointmentActivityCard;
