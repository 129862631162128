import { useState } from "react";

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction";

import { ToolTipPopUp } from "../../pages/calender/ToolTipPopUp";
import { DialogueBoxCard } from "./DialogueBoxCard";
import { DialogueBoxHeader } from "./DialogueBoxHeader";
import { DialogueInnerBox } from "./DialougueInnerBox";
import { DialougueBoxInput } from "./Index";
import { SelectClient } from "./SelectClient";
import { MassageIndex } from './Massage/MassageIndex';

export default function FullyCalendar(props) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [appointmentBoxOpen, setAppointmentBoxOpen] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [clickedDate, setClickedDate] = useState(null);
    const [headerToolbar, setHeaderToolbar] = useState(props?.headerToolbar);

    const slotLabelContent = ({ date, text }) => {
        return (
            <div className="custom-slot-label1">
                <span>{text}</span>
            </div>
        );
    };

    const handleDateClick = (info) => {
        const clientX = info.jsEvent.clientX;
        const clientY = info.jsEvent.clientY;
        const tooltipX = clientX + 10; // Adjust the offset if needed
        const tooltipY = clientY + 10 + window.scrollY; // Add scroll offset

        setClickedDate(info.dateStr);
        setTooltipOpen(true);
        setTooltipPosition({ x: tooltipX, y: tooltipY });
    };

    const handleCloseTooltip = () => {
        setTooltipOpen(false);
    };

    const appointmentBoxBtnOpen = () => {
        // Handle button 1 click action
        setAppointmentBoxOpen(true);
    };
    const appointmentBoxBtnClose = () => {
        // Handle button 1 click action
        setAppointmentBoxOpen(false);
    };

    const blockedTimeBtn = () => {
        // Handle button 2 click action
    };

    const handleSize = (e) => {
        const contentApi = e.view.calendar;
        if (window.innerWidth < 800) {
            contentApi.changeView('timeGridDay');
            setHeaderToolbar({
                right: 'prev today next',
                left: null
            });
        } else {
            setHeaderToolbar({
                right: 'timeGridDay timeGridWeek dayGridMonth dayGridYear',
                left: 'prev today next'
            });
        }
    };

    return (
        <div className="h-full p-5">
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={props?.initialView}
                headerToolbar={headerToolbar}
                slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: true,
                    meridiem: 'long'
                }}
                slotLabelInterval={{ hour: 1 }}
                allDaySlot={false}
                slotLabelContent={slotLabelContent}
                dayHeaderContent={(args) => (
                    <div className='headerMainDiv'>
                        <div>{args.date.toLocaleDateString('en-US', { weekday: 'short' })}</div>
                        <div className='headerDate'>{args.date.getDate()}</div>
                    </div>
                )}
                editable={true}
                selectable={true}
                dateClick={handleDateClick}
                windowResize={handleSize}
                height='100%'
            />
            {tooltipOpen && (
                <ToolTipPopUp 
                    tooltipOpen={tooltipOpen}
                    clickedDate={clickedDate}
                    appointmentBtn={appointmentBoxBtnOpen}
                    blockedTimeBtn={blockedTimeBtn}
                    handleCloseTooltip={handleCloseTooltip}
                    tooltipPosition={tooltipPosition}
                />
            )}
            {appointmentBoxOpen && (
                <DialogueBoxCard >
                    <DialogueBoxHeader
                        handleCloseDialogueBox={appointmentBoxBtnClose}
                    />
                    <DialogueInnerBox>
                        <DialougueBoxInput />
                        <SelectClient />
                        <MassageIndex />
                    </DialogueInnerBox>
                </DialogueBoxCard>
            )}
        </div>
    )
}
