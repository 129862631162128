import { Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': { fontSize: 28, color: '#6456E2' },
    '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
            color: '#000000',
            opacity: '0.38'
        }
    }
});
