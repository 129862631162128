import { useState } from "react";

import { 
  Link, 
  useNavigate 
} from "react-router-dom";

import { 
  Button,
  IconButton, 
  InputAdornment, 
  OutlinedInput 
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="w-full bg-[#FAF7FF] grid overflow-hidden lg:h-screen grid-cols-1 lg:grid-cols-2">
      <div className="relative w-full  h-screen">
        <div className="absolute z-30 left-4 top-4 ">
          <Link to="/sign-in">
            <img src="/signin/back.png" alt="" />
          </Link>
        </div>
        <img
          src="/signup/create.png"
          className="w-full relative -right-[0px]"
          alt=""
        />
        <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full z-10">
          <div className="bg-white p-5 rounded-[18px] flex items-center justify-center  w-[77%] h-[70%]">
            <div>
              <div className="max-w-[396px] mx-auto">
                <h1 className="text-pr text-[24px] pl-1 leading-[26px]   ">
                  Welcome back to your business account, Jacob
                </h1>
                <p className=" text-[14px] mt-2 text-sr ">
                  Enter your password to log in as Jacobjones@gmail.com
                </p>
                <div className="mt-5">
                  <div className="text-pr text-md font-bold">
                    Password
                  </div>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    fullWidth
                    className='my-1'
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="mt-2">
                  <Link to="/forgot-password" className="text-[16px] text-purp">
                    Forgot password?
                  </Link>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  className='rounded-2xl mt-5 w-full'
                  // onClick={handleCreateAccount}
                >
                  Log in
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full hidden lg:block">
        <img
          src="/signin/loginf.png"
          className="w-full h-full object-cover"
          alt=""
        />
      </div>
    </div>
  );
};

export default Login;
