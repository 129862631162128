import React, { useState } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const TodayAppointmentCard = ({ title }) => {
  const appointments = []; // Your appointments array

  return (
    <div className="relative border border-gray-300 bg-white shadow-lg rounded-lg p-6 max-w-[55rem] mt-2">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-manrope mb-4">{title}</h2>
      </div>
      {appointments.length === 0 ? (
        <div className="flex flex-col items-center justify-center space-y-2 h-48 mt-10 ">
          <CalendarMonthOutlinedIcon sx={{ fontSize: 60 }} />
          <p className="font-manrope text-2xl">No Appointments Today</p>
          <p className="font-manrope text-xl">
            Visit the <span className="text-blue-500">Calendar</span> section to
            add some appointments
          </p>
        </div>
      ) : (
        <div>{/* Display appointments */}</div>
      )}
    </div>
  );
};

export default TodayAppointmentCard;
