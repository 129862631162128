import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import React from 'react';

const AddressInformation = () => {
  return (
    <div className="border border-indigo-200 custom-border rounded-lg p-6 mt-4 lg w-full mx-auto">
           
            <div className="grid grid-cols-3 gap-4 items-center mb-4">
              <div className="flex flex-col">
                <p className="text-sm">Address</p>
                <p className="text-base">London</p>
              </div>

              <div className="flex flex-col">
                <p className="text-sm">Apt./Suite etc</p>
                <Button size="small" startIcon={<AddIcon />} className="text-[#6456E2]   justify-start">
                  Add
                </Button>
              </div>

              <div className="flex flex-col justify-between">
                <div className='grid grid-cols-2 gap-2'>
                  <div>
                    <p className="text-sm">District</p>
                    <div className="flex items-center justify-between">
                      <p className="text-base">Area name</p>
                    </div>
                  </div>
                  <Button size="small" startIcon={<EditIcon />} className="text-[#6456E2]  justify-end">
                    Edit
                  </Button>
                </div>
              </div>
            </div>

           
            <div className="grid grid-cols-3 gap-4 items-center mb-4 py-2">
              <div className="flex flex-col">
                <p className="text-sm">City</p>
                <p className="text-base">City name</p>
              </div>

              <div className="flex flex-col">
                <p className="text-sm">Region</p>
                <Button size="small" startIcon={<AddIcon />} className="text-[#6456E2]   justify-start">
                  Add
                </Button>
              </div>

              <div className="flex flex-col">
                <p className="text-sm">Postcode</p>
                <p className="text-base">Post code</p>
              </div>
            </div>

        
            <div className="grid grid-cols-3 gap-4 items-center mb-4">
              <div className="flex flex-col">
                <p className="text-sm">Country</p>
                <p className="text-base">India</p>
              </div>

              <div className="flex flex-col justify-start">
                <p className="text-sm">Directions</p>
                <Button size="small" startIcon={<AddIcon />} className="text-[#6456E2]   justify-start">
                  Add
                </Button>
              </div>
              <div></div>
            </div>
          </div>
  );
};

export default AddressInformation;
