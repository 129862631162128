import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";

import Signup from "./pages/signup/Signup";
import Signin from "./pages/signin/Signin";
import Login from "./pages/signin/Login";
import ForgotPassword from "./pages/signin/ForgotPassword";
import ResetPassword from "./pages/signin/ResetPassword";
import AccountSetup from "./pages/account-setup";
import { Provider } from "react-redux";
import store from "./store/store";
import theme from "./theme";
import { Calender } from "./pages/calender/Calender";
import { LayoutIndex } from "./layout/Index";
import Home from "./pages/home";
import Sales from "./pages/sales/index.jsx";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Signup />} />
            <Route path="/sign-in" element={<Signin />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/account-setup" element={<AccountSetup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/" element={<LayoutIndex />}>
              <Route path="/home" element={<Home />} />
              <Route path="/calendar" element={<Calender />} />
              <Route path='/sales' element={<Sales />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
