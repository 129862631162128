import { 
  Link, 
  useNavigate 
} from "react-router-dom";

import { 
  useSelector, 
  useDispatch 
} from 'react-redux'; 

import { useState } from "react";

import { 
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment, 
  OutlinedInput, 
  TextField 
} from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { MuiTelInput } from "mui-tel-input";

import { validatePhoneNumberLength } from 'libphonenumber-js'

import { countries } from "../../utils/countries";

const Signup = () => {
  const [agree, setAgree] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const count = useSelector((state) => state.counter.value);
  const navigate = useNavigate(); 

  const handleCreateAccount = (e) => {
    navigate('/account-setup');
  };

  return (
    <div className="w-full bg-[#FAF7FF] grid overflow-hidden lg:h-screen grid-cols-1 lg:grid-cols-2">
      <div className="relative w-full h-screen">
        <img
          src="/signup/create.png"
          className="w-full relative -right-[1px]"
          alt=""
        />
        <div className="flex items-center justify-center absolute top-0 left-0 w-full h-full z-10">
          <div className="bg-white p-5 rounded-[18px] flex items-center justify-center  w-[87%] h-[90%]">
            <div>
              <h1 className="text-pr text-[24px] text-center font-semibold ">
              Welcome to Serv-ify Partner Onboarding
              </h1>
              <p className="text-[14px] mt-5 traking-[2%] leading-[16.8px] text-sr text-center">
              Join Serv-ify and expand your reach to thousands of potential customers. <br />
              Let's get started!
              </p>
              <form 
                className="max-w-[396px] mt-7 mx-auto"
                onSubmit={handleCreateAccount}
              >
                <div>
                  <div className="text-pr text-md font-bold">
                    Full Name *
                  </div>
                  <TextField 
                    fullWidth 
                    id="firstName"
                    name="firstName"
                    placeholder='Enter your first name'  
                    className='my-1'
                    size="small"
                    required
                  />
                </div>
                <div className="mt-5">
                  <div className="text-pr text-md font-bold">
                    Email *
                  </div>
                  <TextField 
                    fullWidth 
                    id="email"
                    name="email"
                    placeholder='Enter your email'  
                    className='my-1'
                    size="small"
                    required        
                  />
                </div>
                <div className="mt-5">
                  <div className="text-pr text-md font-bold">
                    Password *
                  </div>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    fullWidth
                    className='my-1'
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                <div className="mt-5">
                  <div className="text-pr text-md font-bold">
                    Mobile Number *
                  </div>
                  <MuiTelInput 
                    size='small'
                    defaultCountry="GR"
                    // focused
                    value={phoneNumber} 
                    fullWidth
                    required
                    onChange={(value, info) => {
                      if (validatePhoneNumberLength(value, info.countryCode) === 'TOO_LONG' ) {
                        return;
                      }
                      setPhoneNumber(value);
                    }} 
                  />
                </div>
                <div className="mt-5">
                  <div className="text-md font-bold">
                    Country *
                  </div>
                  <Autocomplete
                    id="country-select-demo"
                    // value={curCountry}
                    // onChange={(event, newValue) => {
                    //     handleCountryChange(newValue);
                    // }}
                    size='small'
                    className='my-1'
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          alt=""
                        />
                        {option.label} ({option.code})
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Country"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                        required
                        // error={!curCountry}
                      />
                    )}
                  />
                </div>
                <div className=" mt-5 px-1 w-full flex items-center gap-3">
                  <input
                    type="checkbox"
                    onChange={(e) => setAgree(e.target.checked)}
                    name=""
                    className="custom-checkbox"
                    id=""
                  />
                  <p className="text-[14px] text-pr leading-[16.8px]">
                    I agree to the{" "}
                    <span className="cursor-pointer text-purp">
                      Privacy Policy, Terms of Service and Terms of Business.
                    </span>
                  </p>
                </div>
                <Button
                  type='submit'
                  variant="contained"
                  className='rounded-2xl mt-5 w-full'
                  disabled={!agree}
                >
                  Create Account
                </Button>
                <p className="text-sr text-center mt-5 text-[14px]"> Already haven an account?   <Link className="text-purp mt-5" to="/sign-in">
                    Sign in
                  </Link></p>

                  <p className="mt-4 text-center text-[14px] text-sr tracking-[2%] leading-[20px]">
                  This site is protected by reCAPTCHA <br />
                  <Link className="text-purp" to="/privacy-policy">
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link className="text-purp" to="/privacy-policy">
                    Terms of Service
                  </Link>{" "}
                  apply.
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full hidden lg:block">
        <img
          src="/signup/right.png"
          className="w-full h-full object-cover"
          alt=""
        />
      </div>
    </div>
  );
};

export default Signup;
