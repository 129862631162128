import GraphCard from "../../components/home/GraphCard";
import UpcomingAppointmentCard from "../../components/home/UpcomingAppointmentCard";
import AppointmentActivityCard from "../../components/home/AppointmentsActivityCard";
import TodayAppointmentCard from "../../components/home/TodayAppointmentCard";
import TopServicesCard from "../../components/home/TopServicesCard";
import TopTeamMemberCard from "../../components/home/TopTeamMemberCard";

export default function Home() {
  return (
    <div className="flex flex-col px-4 py-5 mb-5">
      <div className="w-full">
        <div className="grid grid-cols-2 gap-10 mb-10">
          <GraphCard title="Recent Sales" />
          <UpcomingAppointmentCard title="Upcoming Appointments" />
          <AppointmentActivityCard title="Appointments activity" />
          <TodayAppointmentCard title="Today's next appointments" />
          <TopServicesCard title="Top Services" />
          <TopTeamMemberCard title="Top team member" />
        </div>
      </div>
    </div>
  );
}
