import React from "react";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";

const TopServicesCard = ({ title }) => {
  // Sample data
  const services = [
    { service: "Reflexology", thisMonth: "1", lastMonth: "0" },
    { service: "Massage", thisMonth: "2", lastMonth: "1" },
    { service: "Acupuncture", thisMonth: "3", lastMonth: "2" },
  ];

  return (
    <div className="relative border border-gray-300 bg-white shadow-lg rounded-lg p-6 max-w-[55rem] mt-2 ml-10">
      <h2 className="text-xl font-manrope mb-4 ">{title}</h2>
      {services.length === 0 ? (
        <div className="flex flex-col items-center justify-center space-y-2 h-48">
          <PrivacyTipOutlinedIcon sx={{ fontSize: 48 }} />
          <p className="text-lg text-gray-600">No Data Found</p>
        </div>
      ) : (
        <table className="w-full">
          <thead>
            <tr className="">
              <th className=" font-manrope px-4 py-2 text-left">Service</th>
              <th className=" font-manrope px-4 py-2 text-right">This Month</th>
              <th className=" font-manrope px-4 py-2 text-right">Last Month</th>
            </tr>
          </thead>
          <tbody>
            {services.map((service, index) => (
              <tr key={index} className={index % 2 === 0 ? "" : ""}>
                <td className="px-4 py-2 text-left">{service.service}</td>
                <td className="px-4 py-2 text-right">{service.thisMonth}</td>
                <td className="px-4 py-2 text-right">{service.lastMonth}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TopServicesCard;
