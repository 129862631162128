import { 
  Button, 
  TextField 
} from "@mui/material";

export default function AccountBusinessName(props) {
  const { onClickNext } = props;

  const handleClickNext = (e) => {
    e.preventDefault();
    onClickNext();
  };

  return (
    <form className="w-full flex flex-col" onSubmit={handleClickNext}>
      <div className="w-full text-center space-y-3">
        <div className="text-2xl">
          Tell us about your business
        </div>
        <div className="text-xl">
          Fill out the following details to create your business profile.
        </div>
      </div>
      <div className="mx-3 mt-8 space-y-5">
        <div className="">
          <div className="text-xl">
            Business Name *
          </div>
          <TextField
            size="small"
            className=""
            fullWidth
            required
            id="businessName"
            name="businessName"
            placeholder="Serv-ify"
          />
        </div>
        <div className="">
          <div className="text-xl">
            Website
          </div>
          <TextField
            size="small"
            className=""
            fullWidth
            id="businessName"
            name="businessName"
            placeholder="www.serv-ify.com"
          />
        </div>
      </div>
      <div className='mt-8'>
        <Button
          type="submit"
          variant="contained"
          className='rounded-2xl'
        >
            Next Step
        </Button>
      </div>
    </form>
  )
}
