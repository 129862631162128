import { useState } from 'react';

export default function ServiceCard(props) {
    const [selected, setSelected] = useState(false);

    const { service, onSelect } = props;

    const handleClickCard = () => {
        onSelect(service);
        setSelected(prev => !prev);
    };

    return (
        <div 
            style={{backgroundColor: `${service?.color}`}} 
            className={`text-center p-2 rounded-xl text-pr font-bold cursor-pointer shadow-lg ${selected ? 'border-2 border-solid border-pr' : ''}`}
            onClick={handleClickCard}
        >
            <img
                className="w-full"
                src={service?.image}
            />
            <div>
                {service?.name}
            </div>
        </div>
    )
}
