import { useState } from 'react';

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';

const AccountsoftwareUsing = (props) => {
    const { onClickNext } = props;
    const [softwareUsing, setSoftwareUsing] = useState();

    const handleChange = (event) => {
        setSoftwareUsing(event.target.value);
    };

    const handleClickNext = (e) => {
        e.preventDefault();
        onClickNext();
    };

    return (
        <form className="flex flex-col justify-center" onSubmit={handleClickNext}>
            <div className="w-full text-center space-y-3">
                <h4 className="text-2xl">
                    Which Software are you currently using ?
                </h4>
                <div className="text-xl">
                    If you’re looking to switch, we can help speed up your business setup<br/>
                    and import your data into your servify account        
                </div>
            </div>
            <div className="bg-white w-full my-3 p-6 rounded-md shadow-lg">
                <div className="flex flex-col space-y-1 gap-2 mb-2">
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="softwareUsing"
                        value={softwareUsing}
                        onChange={handleChange}
                        defaultValue="Other"
                        className='mt-2 flex flex-col gap-2'
                    >
                        {/* todo: use constant value */}
                        {['Acuity', 'Booksy', 'Janeapp', 'Kitomba', 'Other'].map((size, index) => (
                            <FormControlLabel
                                key={index}
                                value={size}
                                control={<Radio color="primary" />}
                                label={size}
                                className='text-gray-600'
                            />
                        ))}
                    </RadioGroup>
                </div>
            </div>
            <div className='mt-8'>
                <Button
                    type="submit"
                    variant="contained"
                    className='rounded-2xl'
                >
                    Next Step
                </Button>
            </div>
        </form>
    );
};

export default AccountsoftwareUsing;
