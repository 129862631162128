import { Grid, IconButton } from '@mui/material';
import { ReactComponent as Person } from '../../assets/side-bar-icons/person.svg';
import { ReactComponent as Plus } from '../../assets/side-bar-icons/plus.svg';

export const SelectClient = () => {
    return (
        <div className="dialogue-box-inner-border-style px-5 py-4 my-4">
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <IconButton className='personBgIcon'>
                        <Person />
                    </IconButton>
                </Grid>
                <Grid item className='pl-4 w-[190px] h-[38px] inner-text'>
                    Select Service <br />Leave empty for walk-in
                </Grid>
                <Grid item style={{ marginLeft: 'auto' }}>
                    <IconButton >
                        <Plus />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    )
}