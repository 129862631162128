import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import CalendarIcon from '../../assets/side-bar-icons/calendar-black.svg'; // Updated to use as an img src
import BlockIcon from '../../assets/side-bar-icons/block.svg'; // Updated to use as an img src

export const ToolTipPopUp = ({ tooltipOpen, tooltipPosition, clickedDate, appointmentBtn, blockedTimeBtn, handleCloseTooltip }) => {
    if (!tooltipOpen || !tooltipPosition) {
        return null; // If tooltip is not open or tooltip position is not defined, don't render anything
    }

    return (
        <Tooltip
          open={tooltipOpen}
          placement="right"
          title={
            <div className="bg-white border border-gray-300 p-2 shadow-lg">
                <div className="tooltip-header">
                    <span className="block text-gray-800 text-sm">10:00 am</span>
                </div>
                <div className="tooltip-content flex flex-col space-y-2">
                    <Button
                        className='text-blue-500 hover:text-blue-700 flex items-center'
                        onClick={appointmentBtn}
                    >
                        <img src={CalendarIcon} alt="Calendar" className="h-5 w-5 mr-2" />
                        Add appointment
                    </Button>
                    <Button
                        className='text-blue-500 hover:text-blue-700 flex items-center'
                        onClick={blockedTimeBtn}
                    >
                        <img src={BlockIcon} alt="Block" className="h-5 w-5 mr-2" />
                        Add Block Time
                    </Button>
                </div>
            </div>
          }
        >
          <div className="invisible" style={{ position: 'absolute', left: tooltipPosition.x, top: tooltipPosition.y }}></div>
        </Tooltip>
    );
};
