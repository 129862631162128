export const services = [
    {
        name: "Barbershop",
        image: "/account-setup/barber.png",
        color: "#D7EDFF"
    },
    {
        name: "Hairdressing",
        image: "/account-setup/hair-salon.png",
        color: "#DAD4FF"
    },
    {
        name: "Nails",
        image: "/account-setup/nails.png",
        color: "#FFE2EB"
    },
    {
        name: "Makeup",
        image: "/account-setup/make-up.png",
        color: "#FFE4C1"
    },
    {
        name: "Facial & Skin Care",
        image: "/account-setup/skincare-clinics.png",
        color: "#FFFD92"
    },
    {
        name: "Hair Removal",
        image: "/account-setup/tanning-salons.png",
        color: "#DAFFF6"
    },
    {
        name: "Dentists",
        image: "/account-setup/dental.png",
        color: "#FFE4C1"
    },
    {
        name: "Doctors",
        image: "/account-setup/doctor.png",
        color: "#CFFFCA"
    },
    {
        name: "Mental Health",
        image: "/account-setup/mental.png",
        color: "#FFE2EB"
    },
    {
        name: "Veterinary",
        image: "/account-setup/veterinary.png",
        color: "#FFFD92"
    },
    {
        name: "Pet Grooming",
        image: "/account-setup/pet-grooming.png",
        color: "#DAD4FF"
    },
    {
        name: "Pet Walking",
        image: "/account-setup/pet-walking.png",
        color: "#D7EDFF"
    }
];
