import { Typography } from "@mui/material"
import { Badge } from "../../Badge"
import { MassgaeTypes } from "./MassageTypes"

const MessageDummyDate = [
    {massageType: "Classic Massage", timing: '1h', price : "$7"},
    {massageType: "Swedish Massage", timing: '1h', price : "$7"},
    {massageType: "Deep Tissue Massage", timing: '1h', price : "$7"},
]
const HairCuttingDate = [
    {massageType: "Hair Cut", timing: '50 Min', price : "$7"},
]

export const MassageIndex = () => {

    const selectService = () => {
        
    }

    return (
        <div className="my-6">
            <div className="flex text-align-baseline">
                <Typography className="text-[18px] leading-3">Massage</Typography>
                <Badge count={3} />
            </div>
            <div className="flex flex-col gap-[15px] my-6">
                {MessageDummyDate.map((massage) => (
                    <MassgaeTypes
                        data={massage}
                        selectService={selectService}
                    />
                ))}
            </div>
            <div className="flex text-align-baseline">
                <Typography className="text-[18px] leading-3">Hair & Styling</Typography>
                <Badge count={1} />
            </div>
            <div className="flex flex-col gap-[15px] my-6">
                {HairCuttingDate.map((massage) => (
                    <MassgaeTypes
                        data={massage}
                    />
                ))}
            </div>
        </div>
    )
}