import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { IconButton, Menu, MenuItem } from '@mui/material';


const GraphCard = ({ title }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const data = {
        datasets: [{
            label: 'Sales',
            data: [0, 40, 120, 80, 140, 130, 150],
            fill: false,
            borderColor: '#6456E2',
            tension: 0.1
        }, {
            label: 'Appointments',
            data: [80, 90, 70, 100, 110, 120, 95],
            fill: false,
            borderColor: '#21CBE2',
            tension: 0.1
        }]
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!chartRef.current) return;

        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        const ctx = chartRef.current.getContext('2d');
        chartInstanceRef.current = new Chart(ctx, {
            type: 'line',
            data: data,
            options: {
                scales: {
                    x: {
                        type: 'category',
                        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    y: {
                        beginAtZero: true,
                        min: 0,
                        max: 160,
                        ticks: {
                            stepSize: 40,
                            callback: function(value) {
                                return value + '€';
                            }
                        }
                    }
                }
            }
        });

        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [data]);

    return (
        <div className="relative border border-gray-300 bg-white shadow-lg rounded-lg p-6 max-w-[55rem] max-h-[45rem] mt-5 ml-10">
            <div className="flex justify-between items-start">
                <h2 className="text-xl font-manrope">{title}</h2>
                <IconButton onClick={handleClick}>
                    <MoreVertOutlinedIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem onClick={handleClose}>Option 1</MenuItem>
                    <MenuItem onClick={handleClose}>Option 2</MenuItem>
                    <MenuItem onClick={handleClose}>Option 3</MenuItem>
                </Menu>
            </div>
            <p className="font-manrope text-base mb-4">Last 7 Days</p>
            <h1 className="font-manrope text-3xl mb-4">0€</h1>
            <p className="font-manrope text-base mb-4">Appointments 3</p>
            <p className="font-manrope text-base">Appointment Value 170.00€</p>
            <canvas ref={chartRef}></canvas>
        </div>
    );
};

export default GraphCard;
