import { useState } from 'react';

import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';

const AccountTeamSize = (props) => {
    const { onClickNext } = props;
    const [teamSize, setTeamSize] = useState();

    const handleChange = (event) => {
        setTeamSize(event.target.value);
    };

    const handleClickNext = () => {
        onClickNext();
    };

    return (
        <div className=" flex flex-col justify-center">
            <div className="w-full text-center space-y-3">
                <div className="text-2xl">
                    What’s your team size?
                </div>
                <div className="text-xl">
                    This will help us set up your calendar correctly. Don’t worry, this doesn’t change the price - you can have unlimited team members.
                </div>
            </div>
            <div className="bg-white my-3 p-6 rounded-lg shadow-lg">
                <div className="flex flex-col space-y-1 gap-2 mb-2">
                    <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="teamSize"
                        value={teamSize}
                        onChange={handleChange}
                        className='mt-2 flex flex-col gap-2'
                        defaultValue="Its just me"
                    >
                        {['Its just me', '2-5 people', '6-10 people', '11+ people'].map((size, index) => (
                            <FormControlLabel
                                key={index}
                                value={size}
                                control={<Radio color="primary" />}
                                label={size}
                                className='text-gray-600'
                            />
                        ))}
                    </RadioGroup>
                </div>
                <div className="p-4 bg-[#FAF7FF] rounded-lg mb-2">
                    <div className='flex flex-row gap-2'>
                        <LightbulbOutlinedIcon size="lg" />
                        <p className="text-gray-700 text-sm">
                            We’ll add 'Wendy' as an example employee so you can see how the system works. You can manage employees later once you’re in!
                        </p>
                    </div>
                </div>
            </div>
            <div className='mt-8'>
                <Button
                    variant="contained"
                    className='rounded-2xl'
                    onClick={handleClickNext}
                >
                    Next Step
                </Button>
            </div>
        </div>
    );
};

export default AccountTeamSize;
