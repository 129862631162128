
import { ReactComponent as MagnifyingGlassIcon } from '../../assets/side-bar-icons/magnifying-glass.svg';

export const DialougueBoxInput = () => {
    return (
        <div className="relative w-full cursor-pointer">
            <div className=" absolute h-full top-0 left-4 flex items-center justify-center z-20">
                <MagnifyingGlassIcon
                    className="w-4 h-4 text-[#667085]"
                />
            </div>
            <input
                type="text"
                placeholder="Search by service name"
                className="border w-full dialogue-box-inner-border-style dialogue-box-inner-input-style pl-[40.66px]"
            />
        </div>
    )
}