import React from 'react';

import { 
    MobileStepper, 
    styled 
} from '@mui/material';

export const StyledStepper = styled(MobileStepper)({
    '& .MuiLinearProgress-root': { width: "100%"}
});
