import { useState } from 'react';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { 
    Box, 
    Button, 
    Checkbox, 
    FormControlLabel, 
    TextField, 
    Typography 
} from '@mui/material';

import AddressInformation from './AccountLocationDetail';

export default function AccountLocation(props) {
    const { onClickNext } = props;
    const [location, setLocation] = useState('');
    const [showDetails, setShowDetails] = useState(false);

    const handleClickNext = () => {
        onClickNext();
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
        setShowDetails(event.target.value.length > 0);
    };

    const handleNoBusinessAddressChange = (event) => {
        setShowDetails(!event.target.checked);
    };

    return (
        <Box className="w-full flex flex-col p-4">
            <Typography className="text-center text-2xl mb-3 text-pr">
                Set your location
            </Typography>
            <Typography variant="subtitle1" className="text-center mb-6 text-xl">
                Add your business location so your clients can easily find you.
            </Typography>
            <Box className="bg-white w-full p-6 rounded-lg shadow-md mb-4">
                <Typography variant="h6" className="mb-4">
                    Business location
                </Typography>
                <TextField
                    fullWidth
                    value={location}
                    onChange={handleLocationChange}
                    placeholder="Enter your location"
                    InputProps={{
                        startAdornment: (
                        <LocationOnOutlinedIcon className="mr-2" />
                        ),
                    }}
                />
                {showDetails && (
                    <AddressInformation />
                )}
                <FormControlLabel
                    control={<Checkbox onChange={handleNoBusinessAddressChange} />}
                    label="I don’t have a business address (mobile and web service only)"
                    className="mt-4"
                />
            </Box>
            {showDetails && (
                <Box className="w-full ">
                    <Typography variant="h6" className="mb-4">
                        Map location
                    </Typography>
                    <Box className="w-full h-64 bg-gray-300 rounded-lg">
                        {/* TODO ::O Map component should be rendered here */}
                        <Typography className="flex items-center justify-center h-full">
                            Map Placeholder
                        </Typography>
                    </Box>
                </Box>
            )}
            <div className='mt-8'>
                <Button
                    variant="contained"
                    className='rounded-2xl'
                    onClick={handleClickNext}
                >
                    Next Step
                </Button>
            </div>
        </Box>
    );
}
