import { 
    AutoStoriesOutlined, 
    CalendarTodayOutlined, 
    HomeOutlined, 
    LoyaltyOutlined, 
    SentimentSatisfiedOutlined 
} from "@mui/icons-material";

export const sideBarNavigation = [
    {
        name: 'Home',
        href: '/home',
        icon: HomeOutlined
    },
    {
        name: 'Calendar',
        href: '/calendar',
        icon: CalendarTodayOutlined
    },
    {
        name: 'Sales',
        href: '/sales',
        icon: LoyaltyOutlined
    },
    {
        name: 'Clients',
        href: '/',
        icon: SentimentSatisfiedOutlined
    },
    {
        name: 'Catalogue',
        href: '/',
        icon: AutoStoriesOutlined
    }
];
