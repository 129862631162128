import { Search } from '@mui/icons-material';

import { 
    Button,
    InputAdornment, 
    TextField 
} from '@mui/material';

export default function AccountFindBusiness(props) {
    const { onClickNext } = props;

    const handleClickNext = (e) => {
        e.preventDefault();
        onClickNext();
    };

    return (
        <form className="w-full flex flex-col" onSubmit={handleClickNext}>
            <div className="w-full text-center space-y-3">
                <div className="text-2xl">
                    Let's locate your business.
                </div>
                <div className="text-xl">
                    Where is it situated?
                </div>
            </div>
            <div className='mt-12'>
                <div className='text-xl'>Enter Your Legal Business Name or VAT Number *</div>
                <TextField 
                    fullWidth 
                    required
                    id="search"
                    name="search"
                    placeholder='e.g.Chipita'  
                    className='my-1'              
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                    }}
                    // onChange={e => setQuery(e.target.value)}
                    // onKeyDown={(e) => {
                    //     if (e.key === 'Enter') {
                    //         handleClickSearch();
                    //     }
                    // }}
                />
            </div>
            <div className='mt-8'>
                <Button
                    type="submit"
                    variant="contained"
                    className='rounded-2xl'
                >
                    Next Step
                </Button>
            </div>
        </form>
    )
}
