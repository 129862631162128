export const MassgaeTypes = ({ data, selectService }) => {
    


    return (
        <div className="dialogue-box-inner-border-style px-[23px] py-[15px]" >
            <div className="flex justify-between items-center">
                <div className="text-[14px] font-medium leading-3 tracking-[2%]">
                    {data.massageType}
                    <span class="block my-3 text-[14px] font-medium leading-3 tracking-[2%]">{data.timing}</span>
                </div>
                <span className="text-[14px] font-medium leading-3 tracking-[2%]">{data.price}</span>
            </div>
        </div>
    )
}