import { useEffect, useRef, useState } from 'react';
import FullyCalendar from '../../components/calenderComponents/FullyCalendar';

export const Calender = () => {
    const [loading, setLoading] = useState(false);
    
    const [headerToolbar, setHeaderToolbar] = useState();
    const [initialView, setInitialView] = useState();

    useEffect(() => {
        if (window.innerWidth < 800) {
            setHeaderToolbar({
                right: 'prev today next',
                left: null
            });
        } else {
            setHeaderToolbar({
                right: 'timeGridDay timeGridWeek dayGridMonth dayGridYear',
                left: 'prev today next'
            });
        }
        setLoading(true);
    }, []);


    if (window.innerWidth < 800) {
        return (
            <FullyCalendar 
                headerToolbar={{
                    right: 'prev today next',
                    left: null
                }}
                initialView={'timeGridDay'}
            />
        )
    } 
    
    return (
        <FullyCalendar 
            headerToolbar={{
                right: 'timeGridDay timeGridWeek dayGridMonth dayGridYear',
                left: 'prev today next'
            }}
            initialView={'dayGridMonth'}
        />
    )
}
